import { Loading, Authenticated, useGetOne, GetOneParams } from "react-admin";

export default function BannerClickTracking() {
  const {
    data: dataBannerClickTracking,
    isLoading: loadingBannerClickTracking,
    error: errorBannerClickTracking,
  } = useGetOne("dashboard", { id: 290 } as GetOneParams);

  if (loadingBannerClickTracking) {
    return <Loading />;
  }

  if (errorBannerClickTracking) {
    console.error({ error: errorBannerClickTracking });
  }

  return (
    <Authenticated>
      <iframe
        style={{ border: "none" }}
        src={dataBannerClickTracking.metabaseUrl}
        width="100%"
        height="100%"
        allowTransparency
        title="Banner Click Tracking"
      />
    </Authenticated>
  );
}
