import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
import { dataProvider } from ".";

enum countryMapEnum {
  HK = "Hongkong",
  TW = "Taiwan",
  ID = "Indonesia",
  MY = "Malaysia",
}

export class PromotionsReferralBenefitsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;
  countryMap: countryMapEnum;
  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
    this.countryMap = {
      HK: "Hongkong",
      TW: "Taiwan",
      ID: "Indonesia",
      MY: "Malaysia",
    } as unknown as countryMapEnum;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        created_at: {
          _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        created_at: {
          _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }
    if (params.filter?.status === "ACTIVE") {
      filterArray.push({
        start: {
          _lte: moment().toISOString(),
        },
      });
      filterArray.push({
        end: {
          _gte: moment().toISOString(),
        },
      });
    }
    if (params.filter?.status === "EXPIRED") {
      filterArray.push({
        end: {
          _lte: moment().toISOString(),
        },
      });
    }
    if (params.filter?.status === "INACTIVE") {
      filterArray.push({
        start: {
          _gte: moment().toISOString(),
        },
      });
    }
    if (params.filter?.country === "WW") {
      const ww = { worldwide: true };
      filterArray.push({
        country: {
          _contains: ww,
        },
      });
    }
    if (["ID", "HK", "MY", "TW"].includes(params.filter?.country)) {
      const includeCountry = { include: [params.filter.country] };
      filterArray.push({
        country: {
          _contains: includeCountry,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query PromotionsReferralBenefitsList($where: ReferralBenefitsWhereInput) {
          allReferralBenefitsList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              author_id_data {
                id
                name
                email
              } 
              start
              end
              country
              rewards
              limit
              rules
              app
            }
          }
        }
      `,
      variables: {
        where,
      },
    });
    let returnedData = queryResult?.allReferralBenefitsList?.data;
    returnedData = returnedData.map(async (x: any) => {
      // country
      let countryArray = x.country.include.map(
        (c: keyof countryMapEnum) => this.countryMap[c]
      );
      if (x.country.worldwide) countryArray = ["WORLDWIDE"];
      // rewards
      let rewardsReferee: string[] = [];
      let rewardsReferrer: string[] = [];
      let packageIds: string[] = [];
      Object.keys(x.rewards.referee)
        .filter((key) => x.rewards.referee[key] > 0)
        .forEach((rewardKey) =>
          rewardsReferee.push(`${x.rewards.referee[rewardKey]} ${rewardKey}`)
        );
      Object.keys(x.rewards.referrer)
        .filter((key) => x.rewards.referrer[key] > 0)
        .forEach((rewardKey) =>
          rewardsReferrer.push(`${x.rewards.referrer[rewardKey]} ${rewardKey}`)
        );
      [x.rewards.referrer.package, x.rewards.referee.package]
        .filter((rewardPackage) => rewardPackage !== "")
        .forEach((rewardPackage) => packageIds.push(rewardPackage));
      if (packageIds.length > 0) {
        const { data: packageData } = await dataProvider.getList(
          "servicesPackage",
          {
            filter: {
              packageIds,
            },
            pagination: { page: 1, perPage: 1000 },
            sort: {
              field: "item_key",
              order: "ASC",
            },
          }
        );
        packageData
          .filter((packData) => packData.id === x.rewards.referee.package)
          .forEach((packData) => rewardsReferee.push(packData.label));
        packageData
          .filter((packData) => packData.id === x.rewards.referrer.package)
          .forEach((packData) => rewardsReferrer.push(packData.label));
      }
      return {
        ...x,
        country: countryArray,
        rewards: {
          referee: rewardsReferee,
          referrer:
            rewardsReferrer.length === 0 ? ["UNAVAILABLE"] : rewardsReferrer,
        },
      };
    });
    returnedData = await Promise.all(returnedData);
    return {
      data: returnedData,
      total: returnedData.length,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aReferralBenefit($id: UUID) {
          getReferralBenefitsById(id: $id) {
            id
            created_at
            updated_at
            author_id_data {
              id
              name
              email
            }
            start
            end
            country
            rewards
            limit
            rules
            app
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    let returnedData = queryResult?.getReferralBenefitsById;

    return {
      data: returnedData,
    };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const toBeUpdatedData: any = {
      ...params.data,
      app: [params.data.app],
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;
    delete toBeUpdatedData.author_id_data;
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: ReferralBenefitsUpdateInput!) {
          updateReferralBenefitsById(ids: $ids, input: $input) {
            start
            end
            country
            rewards
            limit
            rules
            app
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: toBeUpdatedData,
      },
    });

    return {
      data: queryResult?.updateReferralBenefitsById[0],
    } as UpdateResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });
    const { data: userData } = await this.client.user.get();
    const toBeCreatedData = {
      ...params.data,
      author_id: userData?.id,
      app: [params.data.app],
      country: {
        ...params.data.country,
        ...(!params.data.country.exclude
          ? {
              exclude: [],
            }
          : {}),
        ...(!params.data.country.include
          ? {
              include: [],
            }
          : {}),
      },
      rewards: {
        ...params.data.rewards,
        referrer: {
          ...params.data.rewards.referrer,
          ...(!params.data.rewards.referrer.coin
            ? {
                coin: 0,
              }
            : {}),
          ...(!params.data.rewards.referrer.poin
            ? {
                poin: 0,
              }
            : {}),
          ...(!params.data.rewards.referrer.package
            ? {
                package: "",
              }
            : {}),
        },
      },
    };
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [ReferralBenefitsCreateInput!]!) {
          createReferralBenefits(input: $input) {
            id
            author_id
            start
            end
            country
            rewards
            limit
            rules
            app
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return {
      data: queryResult?.createReferralBenefits[0],
    } as CreateResult;
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    console.log({ method: "delete", resource, params });
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deleteReferralBenefitsById(ids: $ids)
        }
      `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return {
      data: queryResult?.deleteReferralBenefitsById[0],
    } as DeleteResult;
  }
}
