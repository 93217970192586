import mbaas from "./Mbaas";
import { AuthProvider, UserIdentity } from "react-admin";

const HTTP_ERR_CODES = ["401", "403", 401, 403];
let permissions_cache: string[];

// reset cache per 1 minute
setInterval(() => {
  permissions_cache = [];
}, 60000);

export const Auth: AuthProvider = {
  // send username and password to the auth server and get back credentials
  login: async ({ username, password }) => {
    const { error } = await mbaas.client.auth.login("local", {
      email: username,
      password: password,
    });
    //
    if (!error) {
      localStorage.setItem("username", username);
      return Promise.resolve();
    } else {
      console.log({ error });
      return Promise.reject(error);
    }
  },
  // remove local credentials and notify the auth server that the user logged out
  logout: async () => {
    const logoutError = await mbaas.client.user.logout();
    console.log(logoutError);

    if (logoutError) {
      const errMsg = `${logoutError.error?.code}: ${logoutError.error?.status}`;
      // return Promise.reject(errMsg);
      console.log(errMsg);

      return Promise.resolve();
    }
    // else logout OK
    localStorage.removeItem("username");
    return Promise.resolve();
  },
  // when the dataProvider returns an error, check if this is an authentication error
  checkError: async (dataError: any) => {
    // only handles dataprovider error on HTTP 4xx
    // if (dataError > 400 && dataError < 500) {
    const { error } = await mbaas.client.user.get();
    console.log({ dataError, error });
    if (error && HTTP_ERR_CODES.includes(error.status!)) {
      localStorage.removeItem("username");
      return Promise.reject({ redirectTo: "/login" }); // redirect to login
    }
    // }
    return Promise.resolve();
  },
  // when the user navigates, make sure that their credentials are still valid
  checkAuth: async () => {
    const { data } = await mbaas.client.user.get();
    if (data?.roles.includes("Subscriber")) return Promise.reject();
    return mbaas.client.user.isLoggedIn ? Promise.resolve() : Promise.reject();
  },
  // get the user permissions (optional)
  getPermissions: async () => {
    if (permissions_cache && permissions_cache.length > 0) {
      return permissions_cache;
    }
    const { data } = await mbaas.client.user.get();
    if (data?.roles && !data.roles.includes("Subscriber")) {
      permissions_cache = data.roles;
      return data?.roles;
    }
    return [];
  },
  getIdentity: async () => {
    const response = await mbaas.client.user.get();
    return response.data as UserIdentity;
  },
};
