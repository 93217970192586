import { useState } from "react";
import Box from "@mui/material/Box";
import {
  ExpandCircleDown as ExpandCircleDownIcon,
  Toc as TocIcon,
  LiveHelp as LiveHelpIcon,
  ManageAccounts as AccountIcon,
  RunningWithErrors as RunningWithErrorsIcon,
  Category as CategoryIcon,
  CellTower as MvnoIcon,
  MiscellaneousServices as InternalIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  ShoppingBasket as ShoppingBasketIcon,
  Sms as SmsIcon,
  DialerSip as DialerSipIcon,
  ViewComfy as ViewComfyIcon,
  PhoneAndroid,
  Redeem as RedeemIcon,
  LocalPlay as BannerIcon,
  Send as SendIcon,
  Loyalty as LoyaltyIcon,
  GroupAdd as RefferalIcon,
  ContactPhone as ContactPhoneIcon,
  AccountBalanceWallet as PaidIcon,
  Tune as TuneIcon,
  Summarize as SummarizeIcon,
  UploadFile as UploadFileIcon,
  AttachMoney as AttachMoneyIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Sell as TarrifIcon,
  InventoryOutlined as SellProductIcon,
  MedicalServicesOutlined as EmergencyIcon,
  HolidayVillage as HolidayIcon,
  BusinessOutlined as EmbassyIcon,
  NewspaperOutlined as LatesNewsIcon,
  SystemSecurityUpdateWarning as DeviceLogsIcon,
  PrivacyTipOutlined as PrivacyPolicyIcon,
  TimesOneMobiledata as OtpConstraintsIcon,
  DesignServices as PointRulesIcon,
  Grade as RewardIcon,
  Store as StoreIcon,
  QueryStats as QueryStatsIcon,
} from "@mui/icons-material";
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";
import SubMenu from "./SubMenu";

type MenuName =
  | "menuSubscriber"
  | "menuServices"
  | "menuConsumption"
  | "menuInternal"
  | "menuSelfService"
  | "menuDocuments"
  | "menuDiaspora"
  | "menuPromotions";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuSubscriber: false,
    menuServices: false,
    menuConsumption: false,
    menuInternal: false,
    menuSelfService: false,
    menuDocuments: false,
    menuDiaspora: false,
    menuPromotions: false,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState(() => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 260 : 50,
        marginTop: 3,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <MenuItemLink
        to="/users"
        state={{ _scrollToTop: true }}
        primaryText="Users"
        leftIcon={<AccountIcon />}
        resource="users"
        dense={dense}
      />
      <MenuItemLink
        to="/subscribers"
        state={{ _scrollToTop: true }}
        primaryText="Subscribers"
        leftIcon={<PhoneAndroid />}
        resource="subscribers"
        dense={dense}
      />
      {/* <SubMenu
        handleToggle={() => handleToggle("menuSubscriber")}
        isOpen={state.menuSubscriber}
        name="Subscribers"
        icon={<ExpandCircleDownIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/subscriber/registered"
          state={{ _scrollToTop: true }}
          primaryText="Registered"
          resource="registeredSubscribers"
          leftIcon={<AccountBoxIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/subscriber/pending"
          state={{ _scrollToTop: true }}
          primaryText="Pending"
          resource="pendingSubscribers"
          leftIcon={<FaceIcon />}
          dense={dense}
        />
      </SubMenu> */}
      <SubMenu
        dataTestId="services-menu"
        handleToggle={() => handleToggle("menuServices")}
        isOpen={state.menuServices}
        name="Services"
        icon={<ExpandCircleDownIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/services/package"
          state={{ _scrollToTop: true }}
          primaryText="Package"
          resource="packages"
          leftIcon={<CategoryIcon />}
          dense={dense}
          data-testid="menu-package"
        />
        <MenuItemLink
          to="/services/mvno"
          state={{ _scrollToTop: true }}
          primaryText="MVNO"
          resource="servicesMvno"
          leftIcon={<MvnoIcon />}
          dense={dense}
          data-testid="menu-mvno"
        />
        <MenuItemLink
          to="/services/product"
          state={{ _scrollToTop: true }}
          primaryText="Product"
          resource="products"
          leftIcon={<SellProductIcon />}
          dense={dense}
          data-testid="menu-product"
        />
        <MenuItemLink
          to="/services/currencyRates"
          state={{ _scrollToTop: true }}
          resource="currencyRates"
          primaryText="Conversion Rate"
          leftIcon={<CurrencyExchangeIcon />}
          dense={dense}
        />
        <MenuItemLink
          data-testid="tariffs-menu"
          to="/services/tarrifs"
          state={{ _scrollToTop: true }}
          resource="tarrifs"
          primaryText="Tariffs"
          leftIcon={<TarrifIcon />}
          dense={dense}
        />
        <MenuItemLink
          data-testid="rewards-menu"
          to="/services/rewards"
          state={{ _scrollToTop: true }}
          resource="rewardCatalogues"
          primaryText="Rewards"
          leftIcon={<RewardIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/services/payment-channel"
          state={{ _scrollToTop: true }}
          primaryText="Payment Channel"
          resource="paymentChannel"
          leftIcon={<StoreIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuConsumption")}
        isOpen={state.menuConsumption}
        name="Consumptions"
        icon={<ExpandCircleDownIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/usages/purchase"
          state={{ _scrollToTop: true }}
          primaryText="Purchase"
          resource="purchaseLogs"
          leftIcon={<ShoppingBasketIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/usages/mvno"
          state={{ _scrollToTop: true }}
          primaryText="MVNO"
          resource="mvno"
          leftIcon={<MvnoIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/usages/sms"
          state={{ _scrollToTop: true }}
          primaryText="SMS"
          resource="smsLogs"
          leftIcon={<SmsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/usages/voice"
          state={{ _scrollToTop: true }}
          primaryText="Voice"
          resource="voiceLogs"
          leftIcon={<DialerSipIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/usages/reload"
          state={{ _scrollToTop: true }}
          primaryText="Reload"
          resource="reloadLogs"
          leftIcon={<PaidIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/usages/reward"
          state={{ _scrollToTop: true }}
          primaryText="Rewards"
          resource="rewardLogs"
          leftIcon={<RewardIcon />}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        to="/layouts"
        state={{ _scrollToTop: true }}
        resource="layouts"
        primaryText="Layouts"
        leftIcon={<ViewComfyIcon />}
        dense={dense}
      />
      {/* <MenuItemLink
        to="/reports"
        state={{ _scrollToTop: true }}
        resource="reports"
        primaryText="Reports"
        leftIcon={<DownloadForOfflineIcon />}
        dense={dense}
      /> */}

      <SubMenu
        handleToggle={() => handleToggle("menuSelfService")}
        isOpen={state.menuSelfService}
        name="Self-Service CMS"
        icon={<ExpandCircleDownIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/faqs"
          state={{ _scrollToTop: true }}
          resource="faqs"
          primaryText="FAQ"
          leftIcon={<LiveHelpIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/tnc"
          state={{ _scrollToTop: true }}
          resource="tnc"
          primaryText="Terms & Condition"
          leftIcon={<TocIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/privacypolicy"
          state={{ _scrollToTop: true }}
          resource="privacypolicy"
          primaryText="Privacy Policy"
          leftIcon={<PrivacyPolicyIcon />}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        dataTestId="documents-menu"
        handleToggle={() => handleToggle("menuDocuments")}
        isOpen={state.menuDocuments}
        name="Documents"
        icon={<ExpandCircleDownIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/reports"
          state={{ _scrollToTop: true }}
          resource="reports"
          primaryText="Reports"
          leftIcon={<SummarizeIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/documents"
          state={{ _scrollToTop: true }}
          resource="tnc"
          primaryText="Batch Upload"
          leftIcon={<UploadFileIcon />}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle("menuDiaspora")}
        isOpen={state.menuDiaspora}
        name="Diaspora CMS"
        icon={<ExpandCircleDownIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/diasporaEmergency"
          state={{ _scrollToTop: true }}
          resource="diasporaEmergency"
          primaryText="Emergency Number"
          leftIcon={<EmergencyIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/diasporaHoliday"
          state={{ _scrollToTop: true }}
          resource="diasporaHoliday"
          primaryText="Holiday"
          leftIcon={<HolidayIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/diasporaEmbassy"
          state={{ _scrollToTop: true }}
          resource="diasporaEmbassy"
          primaryText="Embassy Info"
          leftIcon={<EmbassyIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/diasporaNews"
          state={{ _scrollToTop: true }}
          resource="diasporaNews"
          primaryText="Latest News"
          leftIcon={<LatesNewsIcon />}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle("menuPromotions")}
        isOpen={state.menuPromotions}
        name="Promotions"
        icon={<RedeemIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/promotions/banner_click_tracking"
          state={{ _scrollToTop: true }}
          primaryText="Banner Tracking"
          leftIcon={<QueryStatsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="promotionsBanner"
          state={{ _scrollToTop: true }}
          primaryText="Banner"
          leftIcon={<BannerIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/promotions/broadcast"
          state={{ _scrollToTop: true }}
          primaryText="Broadcast"
          leftIcon={<SendIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/promotions/flashsale"
          state={{ _scrollToTop: true }}
          primaryText="Sale Programs"
          leftIcon={<LoyaltyIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/promotions/referral_benefits"
          state={{ _scrollToTop: true }}
          primaryText="Referral Benefits"
          leftIcon={<RefferalIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuInternal")}
        isOpen={state.menuInternal}
        name="Internal"
        icon={<AdminPanelSettingsIcon />}
        dense={dense}
        dataTestId="menu-internal"
      >
        <MenuItemLink
          to="/constraintsLimit"
          state={{ _scrollToTop: true }}
          resource="constraintsLimit"
          primaryText="Constraints"
          leftIcon={<TuneIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/virtualMsisdn"
          state={{ _scrollToTop: true }}
          resource="virtualMsisdn"
          primaryText="Virtual MSISDN"
          leftIcon={<ContactPhoneIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/internal/currency-prefix"
          state={{ _scrollToTop: true }}
          resource="currencyMsisdn"
          primaryText="Currency Prefix"
          leftIcon={<AttachMoneyIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/internal/systemConfigs"
          state={{ _scrollToTop: true }}
          resource="systemConfigs"
          primaryText="Configuration"
          leftIcon={<InternalIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/internal/execution-logs"
          state={{ _scrollToTop: true }}
          resource="executionLogs"
          primaryText="Execution Logs"
          leftIcon={<RunningWithErrorsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/internal/device-logs"
          state={{ _scrollToTop: true }}
          resource="deviceLogs"
          primaryText="Device Logs"
          leftIcon={<DeviceLogsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/internal/otpConstraints"
          state={{ _scrollToTop: true }}
          resource="OTPConstraints"
          primaryText="OTP Constraints"
          leftIcon={<OtpConstraintsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/internal/point-rules"
          state={{ _scrollToTop: true }}
          resource="OTPConstraints"
          primaryText="Point Rules"
          leftIcon={<PointRulesIcon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
