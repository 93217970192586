import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { ProviderBase } from "./ProviderBase";
import {
    CreateParams,
    CreateResult,
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult,
} from "react-admin";
import { buildArgs } from "./utils";
import gql from "graphql-tag";
import { dataProvider } from ".";
export class PaymentChannelProviderClass extends ProviderBase<any> {
    client: MobileServiceClient;

    constructor(client: MobileServiceClient) {
        super();
        this.client = client;
    }

    async getList(resource: string, params: GetListParams): Promise<GetListResult<any>> {
        const { filter, sort } = params
        const { page, perPage } = params.pagination;

        const where: Record<string, any> = {};

        const args = buildArgs({
            // @ts-ignore
            order: sort.order,
            size: perPage,
            pageNumber: page,
            sort: sort.field,
        });

        if (filter.id) {
            const {
                data: { item_group: itemGroup },
            } = await dataProvider.getOne('productCatalogsItemGroup', {
                id: filter.id,
            });
            where['item_group'] = {
                _eq: itemGroup,
            }
        }
        if (filter.country) {
            where['country'] = {
                _eq: `${filter.country}`,
            }
        }
        if (filter.payment_channel) {
            where['payment_channel'] = {
                _has_key: `${filter.payment_channel}`,
            }
        }

        const queryResult = await this.client.gql.query({
            query: gql`query allPaymentChannelSelectorsList($where: PaymentChannelSelectorsWhereInput) {
                allPaymentChannelSelectorsList(where: $where, ${args}) {
                    count
                    data {
                        id
                        created_at
                        updated_at
                        item_group
                        payment_channel
                        country
                        app
                    }
                }
            }`,
            variables: {
                where
            }
        })

        if (queryResult.error) {
            console.error(queryResult.error);
            throw new Error("GraphQL query error");
        }

        return {
            data: queryResult.allPaymentChannelSelectorsList.data,
            total: queryResult.allPaymentChannelSelectorsList.count,
        };
    }

    async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {

        const queryResult = await this.client.gql.query({
            query: gql`
            query getPaymentChannelSelectorsById($id: UUID!) {
                getPaymentChannelSelectorsById(id: $id) {
                        id
                        created_at
                        updated_at
                        item_group
                        payment_channel
                        country
                        app
                    }
                }
            `,
            variables: {
                id: params.id,
            },
        });

        return { data: queryResult?.getPaymentChannelSelectorsById };
    }

    async create(resource: string, params: CreateParams<any>): Promise<CreateResult<any>> {
        const productCatalogItemGroup = await dataProvider.getOne('productCatalogsItemGroup', {
            id: params.data.item_group,
        });

        if (!productCatalogItemGroup || !productCatalogItemGroup.data) {
            throw new Error("Failed to retrieve productCatalogItemGroup");
        }

        const toBeCreatedData = {
            ...params.data,
            item_group: productCatalogItemGroup.data.item_group,
        }

        const queryResult = await this.client.gql.mutation({
            mutation: gql`
                mutation createPaymentChannelSelectors($input: [PaymentChannelSelectorsCreateInput!]!) {
                    createPaymentChannelSelectors(input: $input) {
                        id
                        created_at
                        updated_at
                        item_group
                        payment_channel
                        country
                        app
                    }
                }
            `,
            variables: {
                input: toBeCreatedData
            }
        });

        if (queryResult.error) {
            throw new Error("Failed Create");
        }

        return {
            data: queryResult.createPaymentChannelSelectors
        } as CreateResult
    }

    async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
        const toBeUpdatedData: any = {
            ...params.data,
        };
        delete toBeUpdatedData.id;
        delete toBeUpdatedData.created_at;
        delete toBeUpdatedData.updated_at;
        const queryResult = await this.client.gql.mutation({
            mutation: gql`
            mutation ($ids: [UUID!]!, $input: PaymentChannelSelectorsUpdateInput!) {
                updatePaymentChannelSelectorsById(ids: $ids, input: $input) {
                    id
                    created_at
                    updated_at
                    item_group
                    payment_channel
                    country
                    app
                }
            }
            `,
            variables: {
                ids: [`${params.id}`],
                input: toBeUpdatedData,
            },
        });

        return { data: queryResult?.updatePaymentChannelSelectorsById[0] } as UpdateResult;
    }
}