import { Admin, CustomRoutes, Resource, defaultTheme } from "react-admin";
import Dashboard from "./component/Dashboard";
import UsersComponent from "./component/Users";
import FaqsComponent from "./component/Faqs";
import TncComponent from "./component/TermsAndCondition";
import PrivacyPolicyComponent from "./component/PrivacyPolicy";
import DiasporaEmergencyComponent from "./component/DiasporaEmergency";
import DiasporaHolidayComponent from "./component/DiasporaHoliday";
import DiasporaLatestNewsComponent from "./component/DiasporaLatestNews";
import { SmsLogList } from "./component/SmsLogs/List";
import { VoiceLogList } from "./component/VoiceLogs/List";
import { ReloadLogList } from "./component/ReloadLogs/List";
import { PurchaseLogList } from "./component/PurchaseLogs/List";
import { authProvider, dataProvider } from "./provider";
import LoginScreen from "./component/LoginScreen";
import { createBrowserHistory as createHistory } from "history";
import { Layout } from "./layout";
import { Route } from "react-router-dom";
import ForgotPassword from "./component/ForgotPassword";
import ResetPassword from "./component/ResetPassword";
import EmailVerified from "./component/EmailVerified";
import DiasporaEmbassyComponent from "./component/DiasporaEmbassyInfo";
import SubscriberComponent from "./component/Subscriber";
import { ServicesProductList } from "./component/Services/Product/List";
import { ServicesMvnoList } from "./component/Services/Mvno/List";
import { ServicesTariffsList } from "./component/Services/Tarrifs/List";
import { ServicesPackageList } from "./component/Services/Package/List";
import { PaymentChannelList } from "./component/Services/PaymentChannel/List";
import BannerPromotionsList from "./component/Promotions/Banner";
import BroadcastList from "./component/Promotions/Broadcast/List";
import { CreateBroadcast } from "./component/Promotions/Broadcast/Create";
import FlashsaleList from "./component/Promotions/Flashsale/List";
import Productlayout from "./component/ProductLayout";
import ConstraintsLimit from "./component/Internal/Constraints";
import { ReportsList } from "./component/Documents/Reports/ListPage";
import { ReferralBenefitsList } from "./component/Promotions/ReferralBenefits/List";
import { DocumentsList } from "./component/Documents/Batch/ListPage";
import { VirtualMsisdnList } from "./component/Internal/VirtualMsisdn/ListPage";
import { CurrencyRatesList } from "./component/Services/CurrencyRates/ListPage";
import { ExecutionLogsList } from "./component/Internal/ExecutionLogs/List";
import { CurrencyMsisdnList } from "./component/Internal/CurrencyMsisdn/List";
import { SystemConfigsList } from "./component/Internal/SystemConfigs/ListPage";
import { FirebaseRemoteConfigsList } from "./component/Internal/FirebaseRemoteConfigs/ListPage";
import { OtpConstraintsList } from "./component/Internal/OtpConstraints/ListPage";
import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";
import { DeviceLogsList } from "./component/Internal/DeviceLogs/List";
import { MvnoList } from "./component/Mvno/List";
import { PointRulesList } from "./component/Internal/PointRules/ListPage";
import { RewardsList } from "./component/Services/Rewards/ListPage";
import { UsageRewardList } from "./component/Reward/ListPage";
import BannerClickTracking from "./component/Promotions/Banner/BannerClickTracking";

/** import LinkAccount from "./component/LinkAccount";  */

const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: "#9381ff",
      },
      secondary: {
        main: "#5e548e",
      },
    },
  })
);

function App() {
  const history = createHistory();

  return (
    <Admin
      title="m21-console"
      layout={Layout}
      history={history}
      authProvider={authProvider}
      dataProvider={dataProvider}
      theme={theme}
      dashboard={Dashboard}
      loginPage={LoginScreen}
    >
      <Resource name="users" {...UsersComponent} />
      {/* Subscribers */}
      <Resource name="subscribers" {...SubscriberComponent} />

      {/* Services */}
      <Resource name="packages" />
      <Resource name="servicesMvno" />
      <Resource name="products" />
      <Resource name="conversionRates" />
      <Resource name="tarrifs" />

      {/* Consumptions */}
      <Resource name="purchaseLogs" />
      <Resource name="voiceLogs" />
      <Resource name="reloadLogs" />
      <Resource name="mvno" />

      {/* <Resource name="layouts" /> */}

      {/* Self Service CMS */}
      <Resource name="faqs" {...FaqsComponent} />
      <Resource name="tnc" {...TncComponent} />
      <Resource name="privacypolicy" {...PrivacyPolicyComponent} />

      {/* Diaspora */}
      <Resource name="diasporaEmergency" {...DiasporaEmergencyComponent} />
      <Resource name="diasporaHoliday" {...DiasporaHolidayComponent} />
      <Resource name="diasporaEmbassy" {...DiasporaEmbassyComponent} />
      <Resource name="diasporaNews" {...DiasporaLatestNewsComponent} />

      {/* Banner Promotions */}
      <Resource name="promotionsBanner" {...BannerPromotionsList} />

      {/* Constraints */}
      <Resource name="constraintsLimit" {...ConstraintsLimit} />

      {/* Internal */}
      <Resource name="internalConversion" />
      <Resource name="internalConfig" />
      <Resource name="internalExecution" />

      {/* CUSTOM */}
      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/email-verification-result" element={<EmailVerified />} />
      </CustomRoutes>
      {/* USAGES */}
      <CustomRoutes>
        <Route path="/layouts" element={<Productlayout />} />

        {/* <Route path="/link-account" element={<LinkAccount />} /> */}
        <Route path="/usages/purchase" element={<PurchaseLogList />} />
        <Route
          path="/usages/purchase/:id/show/*"
          element={<PurchaseLogList />}
        />
        <Route path="/usages/mvno" element={<MvnoList />} />
        <Route path="/usages/mvno/:id/show/*" element={<MvnoList />} />
        <Route path="/usages/sms" element={<SmsLogList />} />
        <Route path="/usages/sms/:id/show/*" element={<SmsLogList />} />
        <Route path="/usages/voice" element={<VoiceLogList />} />
        <Route path="/usages/reload" element={<ReloadLogList />} />
        <Route path="/usages/reload/:id/show/*" element={<ReloadLogList />} />
        <Route path="/usages/voice/:id/show/*" element={<VoiceLogList />} />
        <Route path="/usages/reward" element={<UsageRewardList />} />
        <Route path="/usages/reward/:id/show/*" element={<UsageRewardList />} />
      </CustomRoutes>
      {/* Reports */}
      <CustomRoutes>
        <Route path="/reports" element={<ReportsList />} />
      </CustomRoutes>
      {/* SYSTEM CONFIGS */}
      <CustomRoutes>
        <Route path="/internal/systemConfigs" element={<SystemConfigsList />} />
        <Route
          path="/internal/systemConfigs/create"
          element={<SystemConfigsList />}
        />
        <Route
          path="/internal/systemConfigs/:id/"
          element={<SystemConfigsList />}
        />
        <Route
          path="/internal/systemConfigs/:id"
          element={<SystemConfigsList />}
        />
        <Route
          path="/internal/systemConfigs/:id/show/*"
          element={<SystemConfigsList />}
        />
      </CustomRoutes>

      {/* FIREBASE REMOTE CONFIGS */}
      <CustomRoutes>
        <Route
          path="/internal/firebaseRemoteConfigs"
          element={<FirebaseRemoteConfigsList />}
        />
        <Route
          path="/internal/firebaseRemoteConfigs/:id/"
          element={<FirebaseRemoteConfigsList />}
        />
      </CustomRoutes>
      {/* CONVERSION RATES */}
      <CustomRoutes>
        <Route path="/services/currencyRates" element={<CurrencyRatesList />} />
        <Route
          path="/services/currencyRates/create"
          element={<CurrencyRatesList />}
        />
        <Route
          path="/services/currencyRates/:id/"
          element={<CurrencyRatesList />}
        />
        <Route
          path="/services/currencyRates/:id"
          element={<CurrencyRatesList />}
        />
        <Route
          path="/services/currencyRates/:id/show/*"
          element={<CurrencyRatesList />}
        />
        <Route path="/services/rewards" element={<RewardsList />} />
        <Route path="/services/rewards/create" element={<RewardsList />} />
        <Route path="/services/rewards/:id" element={<RewardsList />} />
      </CustomRoutes>
      {/* OTP Constraints */}
      <CustomRoutes>
        <Route
          path="/internal/otpConstraints"
          element={<OtpConstraintsList />}
        />
        <Route
          path="/internal/otpConstraints/:id/"
          element={<OtpConstraintsList />}
        />
      </CustomRoutes>
      {/* Virtual MSISDN */}
      <CustomRoutes>
        <Route path="/virtualMsisdn" element={<VirtualMsisdnList />} />
        <Route path="/virtualMsisdn/create" element={<VirtualMsisdnList />} />
        <Route path="/virtualMsisdn/:id/" element={<VirtualMsisdnList />} />
        <Route path="/virtualMsisdn/:id" element={<VirtualMsisdnList />} />
        <Route
          path="/virtualMsisdn/:id/show/*"
          element={<VirtualMsisdnList />}
        />
      </CustomRoutes>
      {/* Documents */}
      <CustomRoutes>
        <Route path="/documents" element={<DocumentsList />} />
      </CustomRoutes>
      {/* SERVICES */}
      <CustomRoutes>
        <Route path="/services/tarrifs/*" element={<ServicesTariffsList />} />
        <Route path="/services/mvno" element={<ServicesMvnoList />} />
        <Route path="/services/mvno/create" element={<ServicesMvnoList />} />
        <Route
          path="/services/mvno/:id/show/*"
          element={<ServicesMvnoList />}
        />
        <Route path="/services/mvno/:id/" element={<ServicesMvnoList />} />
        <Route path="/services/product" element={<ServicesProductList />} />
        <Route
          path="/services/product/:id/"
          element={<ServicesProductList />}
        />
        <Route
          path="/services/product/:id/show/*"
          element={<ServicesProductList />}
        />
        <Route path="/services/package" element={<ServicesPackageList />} />
        <Route
          path="/services/package/create"
          element={<ServicesPackageList />}
        />
        <Route
          path="/services/package/:id/"
          element={<ServicesPackageList />}
        />
        <Route
          path="/services/package/:id/show/*"
          element={<ServicesPackageList />}
        />
        <Route
          path="/services/payment-channel"
          element={<PaymentChannelList />}
        />
        <Route
          path="/services/payment-channel/create"
          element={<PaymentChannelList />}
        />
        <Route
          path="/services/payment-channel/:id/"
          element={<PaymentChannelList />}
        />
      </CustomRoutes>
      {/* PROMOTIONS */}
      <CustomRoutes>
        <Route path="/promotions/broadcast/*" element={<BroadcastList />} />
        <Route
          path="/promotions/broadcast/create"
          element={<BroadcastList />}
        />
        <Route path="/promotions/broadcast/:id" element={<BroadcastList />} />
        <Route path="/promotions/flashsale/*" element={<FlashsaleList />} />
        <Route
          path="/promotions/flashsale/create"
          element={<FlashsaleList />}
        />
        <Route path="/promotions/flashsale/:id/" element={<FlashsaleList />} />
        <Route
          path="/promotions/flashsale/:id/show/*"
          element={<FlashsaleList />}
        />
        <Route
          path="/promotions/referral_benefits/*"
          element={<ReferralBenefitsList />}
        />
        <Route
          path="/promotions/referral_benefits/create"
          element={<ReferralBenefitsList />}
        />
        <Route
          path="/promotions/referral_benefits/:id/"
          element={<ReferralBenefitsList />}
        />
        <Route
          path="/promotions/banner_click_tracking"
          element={<BannerClickTracking />}
        />
      </CustomRoutes>
      <CustomRoutes>
        <Route
          path="/internal/execution-logs/*"
          element={<ExecutionLogsList />}
        />
        <Route
          path="/internal/execution-logs/:id/show/*"
          element={<ExecutionLogsList />}
        />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/internal/device-logs/*" element={<DeviceLogsList />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route
          path="/internal/currency-prefix/*"
          element={<CurrencyMsisdnList />}
        />
        <Route
          path="/internal/currency-prefix/:id/*"
          element={<CurrencyMsisdnList />}
        />
        <Route
          path="/internal/currency-prefix/create"
          element={<CurrencyMsisdnList />}
        />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/internal/point-rules/*" element={<PointRulesList />} />
        <Route
          path="/internal/point-rules/create"
          element={<PointRulesList />}
        />
        <Route
          path="/internal/point-rules/:id/*"
          element={<PointRulesList />}
        />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
