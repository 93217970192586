// @ts-nocheck
import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  TextField,
  Pagination,
  DateField,
  FunctionField,
  SelectInput,
  FilterButton,
  Button as RAButton,
  Confirm,
  useCreate,
} from "react-admin";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  Button as MaterialButton,
  Chip,
  Paper,
  Typography,
  TextField as TF,
  Grid,
} from "@mui/material";
import { EditReferralBenefits } from "./Edit";
import { CreateReferralBenefits } from "./Create";
import { DateRange } from "../../DateRange";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setReferralBenefitFilter } from "src/slices/referralBenefitsPage/filterReferralBenefitSlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";
import GetAppIcon from "@mui/icons-material/GetApp";
import { endOfToday, startOfToday, subMonths, format } from "date-fns";
import mbaas from "../../../provider/Mbaas";

const ListActions = ({ referralBenefitsFilter }) => {
  usePersistFilter();

  const [open, setOpen] = React.useState(false);
  const [drOpen, setDrOpen] = React.useState(false);

  const [datex, setDate] = React.useState(new Date());
  const [datey, setDatey] = React.useState(new Date());

  const [authorData, setAuthorData] = React.useState({});
  const navigate = useNavigate();

  const ExportReferredButton = (props) => {
    const getAuthorData = async () => {
      const res = await mbaas.client.user.get();
      if (res.data) {
        return res.data;
      }
      if (res.error) {
        // @ts-ignore
        notify(res.error);
      }
    };
    getAuthorData().then((data) => {
      setAuthorData(data.id);
    });
    //
    const type_name = "complete-referral-data";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;
    //
    console.log("filterValues", props);
    //
    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        author_id: authorData,
        type: type_name,
        status: "queued",
        data: {
          filter: {
            dateGte: moment(new Date(datex)).startOf("day").toISOString(),
            dateLte: moment(new Date(datey)).endOf("day").toISOString(),
            promotorId: props?.subs_id,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      console.log(datex, datey, "JKW");

      setDrOpen(false);
      exportData();
      setOpen(true);
    };

    const handleBtnClick = () => {
      setDrOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"complete-referral-data"}`));
    };
    const DateRangeDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Grid container gap={3}>
            <TF
              id="date"
              label="From"
              type="date"
              value={datex}
              onChange={(e) => {
                setDate(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TF
              id="date"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={datey}
              onChange={(e) => {
                setDatey(e.target.value);
              }}
            />
          </Grid>
        </Paper>
      );
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <RAButton
        variant="contained"
        label="Export Referral Data"
        size="medium"
        onClick={() => handleBtnClick()}
      >
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
        <Confirm
          isOpen={drOpen}
          title="Please select date range of data you want to Export"
          content={<DateRangeDialogContent />}
          onClose={() => setDrOpen(false)}
          confirm="Go To Report"
          onConfirm={() => handleOnClick()}
        />
      </RAButton>
    );
  };

  return (
    <TopToolbar>
      <FilterButton filters={referralBenefitsFilter} />
      <MaterialButton
        color="primary"
        size="small"
        data-testid="create_button"
        component={Link}
        to={{
          pathname: "/promotions/referral_benefits/create",
        }}
      >
        + Add New
      </MaterialButton>
      <ExportReferredButton />
    </TopToolbar>
  );
};

const ReferralBenefitsPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

export const ReferralBenefitsList = () => {
  const TextArrayField = ({ record, source, sub_source }) => {
    let recordSource = record[source];
    if (sub_source) recordSource = recordSource[sub_source];
    return (
      <>
        {recordSource.map((item: any) => (
          <Chip
            sx={{ backgroundColor: "#284b63", color: "#FFFFFF", margin: 0.25 }}
            label={item}
            key={item}
          />
        ))}
      </>
    );
  };
  TextArrayField.defaultProps = { addLabel: true };

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancelDrawer = useCallback(() => {
    navigate(`/promotions/referral_benefits`);
  }, [navigate]);

  const matchCreate = matchPath(
    "/promotions/referral_benefits/create",
    location.pathname
  );
  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath(
      "/promotions/referral_benefits/:id/*",
      location.pathname
    );

  const ReferralBenefitsFilter = [
    <DateRange alwaysOn />,
    <SelectInput
      source="status"
      label="Status"
      choices={[
        { id: "ACTIVE", name: "ACTIVE" },
        { id: "EXPIRED", name: "EXPIRED" },
        { id: "INACTIVE", name: "INACTIVE" },
      ]}
      alwaysOn
      data-testid="search-status"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setReferralBenefitFilter({ status: e.target.value }))
      }
    />,
    <SelectInput
      label="Country"
      source="country"
      choices={[
        { id: "WW", name: "WORLDWIDE" },
        { id: "HK", name: "Hongkong" },
        { id: "ID", name: "Indonesia" },
        { id: "TW", name: "Taiwan" },
        { id: "MY", name: "Malaysia" },
      ]}
      data-testid="search-country"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setReferralBenefitFilter({ country: e.target.value }))
      }
    />,
  ];

  return (
    <>
      <List
        resource="promotionsReferralBenefits"
        title="Promotions &rsaquo; Referral Benefits"
        filters={ReferralBenefitsFilter}
        actions={
          <ListActions referralBenefitsFilter={ReferralBenefitsFilter} />
        }
        perPage={25}
        pagination={<ReferralBenefitsPagination />}
        sort={[{ field: "created_at", order: "DESC" }]}
      >
        <Datagrid
          key={(id) => id}
          bulkActionButtons={false}
          rowClick={
            ((id: Identifier) =>
              navigate(
                `/promotions/referral_benefits/${id}`
              )) as unknown as RowClickFunction
          }
        >
          <DateField
            source="created_at"
            label="Created"
            showTime
            locales="en-HK"
          />
          <FunctionField
            sortable={false}
            label="Platform"
            render={(record) => (
              <TextArrayField record={record} source="app" />
            )}
          />
          <FunctionField
            sortable={false}
            label="Country"
            render={(record) => (
              <TextArrayField record={record} source="country" />
            )}
          />
          <FunctionField
            sortable={false}
            label="Referrer Reward"
            render={(record) => {
              console.log({ record });
              if (record.rewards.referrer.length === 0) {
                return <TextField source="rewards.referrer" />;
              } else {
                return (
                  <TextArrayField
                    record={record}
                    source="rewards"
                    sub_source="referrer"
                  />
                );
              }
            }}
          />
          <FunctionField
            sortable={false}
            label="Referee Reward"
            render={(record) => {
              if (record.rewards.referee.length === 0) {
                return <TextField source="rewards.referee" />;
              } else {
                return (
                  <TextArrayField
                    record={record}
                    source="rewards"
                    sub_source="referee"
                  />
                );
              }
            }}
          />
          <FunctionField
            sortable={false}
            label="Configuration"
            render={(record: RaRecord) => {
              return (
                <div>
                  {record.limit && (
                    <label>
                      <b>Limit:</b>&nbsp;
                      {record.limit}
                    </label>
                  )}
                  {record.rules["on-codeMatch"] && (
                    <>
                      <br />
                      <label>
                        <b>Code match</b>
                      </label>
                    </>
                  )}

                  {record.rules["on-firstReload"] && (
                    <>
                      <br />
                      <label>
                        <b>First reload</b>
                      </label>
                    </>
                  )}
                  {record.rules["on-firstPurchase"] && (
                    <>
                      <br />
                      <label>
                        <b>First purchase</b>
                      </label>
                    </>
                  )}
                </div>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Validity Period"
            render={(record) => {
              const validityObject = {
                from: moment(record.start).format("DD/M/YYYY, h:mm:ss a"),
                until: moment(record.end).format("DD/M/YYYY, h:mm:ss a"),
              };
              return (
                <div>
                  <label>
                    <b>From:</b>
                    <br />
                    {validityObject.from}
                  </label>
                  <br />
                  <label>
                    <b>To:</b>
                    <br />
                    {validityObject.until}
                  </label>
                </div>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Status"
            render={(record) => {
              const start = moment(record.start).toISOString();
              const end = moment(record.end).toISOString();
              let status;
              if (moment(moment().toISOString()).isBetween(start, end)) {
                status = "ACTIVE";
              } else if (moment(moment().toISOString()).isBefore(end)) {
                status = "INACTIVE";
              } else status = "EXPIRED";
              return status;
            }}
          />
        </Datagrid>
      </List>
      <Drawer
        open={!!matchCreate}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchCreate && (
          <CreateReferralBenefits
            onCancel={handleCancelDrawer}
            match={matchCreate}
          />
        )}
      </Drawer>
      <Drawer
        open={!!matchEdit}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchEdit && (
          <EditReferralBenefits
            id={(matchEdit as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </>
  );
};
