// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  BooleanInput,
  useRecordContext,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  DeleteWithConfirmButton,
  RaRecord,
  FunctionField,
  SelectInput
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Swal from "sweetalert2";
import { useFormContext } from "react-hook-form";
import moment from "moment";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: "auto",
    },
  },
});

const availableCountry = [
  { id: "HK", name: "Hongkong" },
  { id: "ID", name: "Indonesia" },
  { id: "TW", name: "Taiwan" },
  { id: "MY", name: "Malaysia" },
];

export const EditReferralBenefits = ({ onCancel, id, ...props }) => {
  const today = moment().toISOString();

  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSubmitHandler = async (data) => {
    await dataProvider
      .update("promotionsReferralBenefits", {
        data,
        id,
      })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/promotions/referral_benefits`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  const ToolbarEdit = () => {
    const record = useRecordContext();
    let disabled = false;
    if (record.start < today) disabled = true;
    return (
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <SaveButton />
        <DeleteWithConfirmButton
          confirmTitle="Promotions &rsaquo; Referral Benefits &rsaquo; Delete"
          redirect={`/promotions/referral_benefits`}
          disabled={disabled}
        />
      </Toolbar>
    );
  };

  return (
    <Edit resource="promotionsReferralBenefits" title=" &rsaquo; Edit">
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        mt={{ xs: 2, sm: 1 }}
        height="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, top: 10, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          sx={{ width: "100%" }}
          onSubmit={onSubmitHandler}
          toolbar={<ToolbarEdit />}
        >
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput
                  source="author_id_data.name"
                  label="Author"
                  disabled
                  fullWidth
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={4}>
                <legend
                  className="MuiFormLabel-root MuiFormLabel-colorPrimary RaRadioButtonGroupInput-label MuiFormLabel-root"
                  style={{
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  <span>Country</span>
                </legend>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      source="country.worldwide"
                      label="Worldwide?"
                      fullWidth
                      className={classes.input}
                      validate={[required()]}
                      data-testid="input-country-worldwide"
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <CountryAutocomplete validate={required()} today={today} />
              </Grid>
              <Grid item xs={4}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <SelectInput
                      label="Platform"
                      source="app"
                      choices={[
                        { id: 'M21', name: 'M21' },
                        { id: 'G2', name: 'G2' },
                        { id: 'GHK', name: 'GHK' },
                      ]}
                      validate={[required()]}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ maxHeight: "5px" }}>
                <legend
                  className="MuiFormLabel-root MuiFormLabel-colorPrimary RaRadioButtonGroupInput-label MuiFormLabel-root"
                  style={{
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  <span>Rewards for REFERRER</span>
                </legend>
              </Grid>
              <Grid item xs={2}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      source="rewards.referrer.coin"
                      label="Coin"
                      fullWidth
                      className={classes.input}
                      validate={[required()]}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      source="rewards.referrer.point"
                      label="Point"
                      fullWidth
                      className={classes.input}
                      validate={[required()]}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <ReferenceInput
                      source="rewards.referrer.package"
                      reference="servicesPackage"
                      label="Package"
                    >
                      <AutocompleteInput
                        fullWidth
                        source="label"
                        optionText="label"
                        label="Package"
                        disabled={record.start < today}
                      />
                    </ReferenceInput>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ maxHeight: "5px" }}>
                <legend
                  className="MuiFormLabel-root MuiFormLabel-colorPrimary RaRadioButtonGroupInput-label MuiFormLabel-root"
                  style={{
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  <span>Rewards for REFEREE</span>
                </legend>
              </Grid>
              <Grid item xs={2}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      source="rewards.referee.coin"
                      label="Coin"
                      fullWidth
                      className={classes.input}
                      validate={[required()]}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      source="rewards.referee.point"
                      label="Point"
                      fullWidth
                      className={classes.input}
                      validate={[required()]}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <ReferenceInput
                      source="rewards.referee.package"
                      reference="servicesPackage"
                      label="Package"
                    >
                      <AutocompleteInput
                        fullWidth
                        source="label"
                        optionText="label"
                        label="Package"
                        disabled={record.start < today}
                      />
                    </ReferenceInput>
                  )}
                />
              </Grid>
              <Grid item xs={10}>
                <RulesRadioGroup
                  label="When applied?"
                  validate={[required()]}
                  today={today}
                />
              </Grid>
              <Grid item xs={2}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      source="limit"
                      label="Limit"
                      fullWidth
                      className={classes.input}
                      validate={[required()]}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid From"
                  source="start"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid Until"
                  source="end"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleForm>
      </Box>
    </Edit>
  );
};

const CountryAutocomplete = ({ validate, today }) => {
  const classes = useStyles();

  const [autocompleteValue, setAutocompleteValue] = useState([]);

  const { getValues, setValue } = useFormContext();
  const worldwide = getValues("country.worldwide");
  let countryField = "",
    contraCountryField = "",
    label = "";
  if (worldwide) {
    countryField = "country.exclude";
    contraCountryField = "country.include";
    label = "Excluding...";
  } else {
    countryField = "country.include";
    contraCountryField = "country.exclude";
    label = "Including...";
  }

  const validityStart = getValues("start");

  useEffect(() => {
    let haloTimeout = setTimeout(() => {
      setAutocompleteValue(
        getValues(countryField)
          ?.map((v) => ({
            id: v,
            name: availableCountry.find((x) => x.id === v)?.name,
          }))
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
      );
    }, 2000);

    return () => clearTimeout(haloTimeout);
  }, [countryField, getValues, worldwide]);

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={availableCountry}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={autocompleteValue}
      onChange={(_, v) => {
        setValue(
          countryField,
          v.map((inc) => inc.id),
          { shouldDirty: true }
        );
        setValue(contraCountryField, []);
        setAutocompleteValue(v);
      }}
      required={validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      disabled={validityStart < today}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          placeholder={`select Country`}
          data-testid={`input-country`}
        />
      )}
    />
  );
};

const RulesRadioGroup = (props) => {
  const { rules, start: validityStart } = useRecordContext();
  const rulesArray = Object.keys(rules);
  const defaultValue = rulesArray.find((key) => rules[key] === true);

  const { setValue } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedRules = { ...rules };
    Object.keys(updatedRules).forEach((rule) => (updatedRules[rule] = false));
    setValue(
      "rules",
      { ...updatedRules, [event.target.value]: true },
      { shouldDirty: true }
    );
  };

  return (
    <FormControl disabled={validityStart < props.today}>
      <FormLabel
        id="demo-row-radio-buttons-group-label"
        style={{
          color: "black",
          fontSize: "smaller",
        }}
      >
        {props.label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        {rulesArray.map((rule) => {
          return (
            <FormControlLabel
              value={rule}
              key={rule}
              control={<Radio />}
              label={rule}
              data-testid={`input-rules-${rule}`}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
