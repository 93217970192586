// @ts-nocheck
import React, { Fragment, useEffect } from "react";
import {
  SimpleForm,
  required,
  Create,
  useNotify,
  useRedirect,
  useRefresh,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  useInput,
  AutocompleteArrayInput,
} from "react-admin";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { dataProvider } from "../../../provider";

export const CreatePaymentChannel = ({ onCancel, ...props }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSubmitHandler = async (data) => {
    const dataSubmit = {
      ...data,
      app: "M21",
    };
    await dataProvider
      .create("paymentChannel", { data: dataSubmit })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/services/payment-channel`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  return (
    <Fragment>
      <Create resource="paymentChannel" title=" &rsaquo; Create" {...props}>
        <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
          <Stack
            direction="row"
            p={0}
            sx={{ position: "absolute", right: 0, zIndex: 100 }}
          >
            <IconButton onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
          <SimpleForm onSubmit={onSubmitHandler}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ReferenceInput
                  filter={{ isVas: true }}
                  source="item_group"
                  reference="productCatalogsItemGroup"
                >
                  <AutocompleteInput
                    fullWidth
                    source="item_group"
                    optionText={(choice?: any) =>
                      choice?.id ? choice.item_group : ""
                    }
                    // optionValue="item_group"
                    label="Item Group"
                    data-testid="search-item-group"
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <FormDataConsumer>
                  {({ formData }) => <CountryInput formData={formData} />}
                </FormDataConsumer>
              </Grid>
              <Grid item xs={12}>
                <FormDataConsumer>
                  {({ formData }) => (
                    <PaymentChannelInput formData={formData} />
                  )}
                </FormDataConsumer>
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
    </Fragment>
  );
};

const CountryInput: React.FC = (props) => {
  const { field: fieldApp } = useInput({
    source: "app",
  });

  const { field: fieldPayment } = useInput({
    source: "payment_channel",
  });

  return (
    <AutocompleteInput
      fullWidth
      size="small"
      source="country"
      label="Country"
      optionValue="id"
      optionText="name"
      choices={[
        { id: "HK", name: "Hong Kong" },
        { id: "TW", name: "Taiwan" },
      ]}
      alwaysOn
      onChange={() => {
        fieldApp.onChange(null);
        fieldPayment.onChange(null);
      }}
      validate={[required()]}
    />
  );
};

const PaymentChannelInput: React.FC = (props) => {
  const { formData } = props;

  const CHOICES: any = {
    TW: [
      { id: "SEVENTW", name: "7-11" },
      { id: "FAMILYTW", name: "Family Mart" },
      { id: "HILIFETW", name: "Hi-life" },
      { id: "OKMATW", name: "OK Mart" },
      { id: "COIN", name: "Coin" },
    ],
    HK: [{ id: "COIN", name: "Coin" }],
  };

  return (
    <AutocompleteArrayInput
      source="payment_channel"
      optionValue="id"
      optionText="name"
      label="Payment Channel"
      fullWidth
      validate={[required()]}
      choices={CHOICES[formData.country] || []}
    />
  );
};
